import { COUNTRY_VALUE_ALL } from '@/constants'

const COUNTRY_CODES_ISOALPHA2 = {
  ar: ['Argentina'],
  au: ['Australia'],
  at: ['Austria'],
  be: ['Belgium'],
  bm: ['Bermuda'],
  bw: ['Botswana'],
  br: ['Brazil'],
  bg: ['Bulgaria'],
  ca: ['Canada'],
  cl: ['Chile'],
  cn: ['China'],
  co: ['Colombia'],
  hr: ['Croatia'],
  cy: ['Cyprus'],
  cz: ['Czech Republic'],
  dk: ['Denmark'],
  eg: ['Egypt'],
  ee: ['Estonia'],
  fi: ['Finland'],
  fr: ['France', 'French Republic'],
  de: ['Germany', 'Federal Republic of Germany'],
  gh: ['Ghana'],
  gr: ['Greece'],
  hk: ['Hong Kong'],
  hu: ['Hungary'],
  is: ['Iceland'],
  in: ['India'],
  id: ['Indonesia'],
  ie: ['Ireland'],
  il: ['Israel'],
  it: ['Italy', 'Italian Republic'],
  ci: ['Ivory Coast', "Côte d'Ivoire"],
  jm: ['Jamaica'],
  jp: ['Japan'],
  jo: ['Jordan'],
  ke: ['Kenya'],
  kw: ['Kuwait'],
  lv: ['Latvia'],
  lt: ['Lithuania'],
  lu: ['Luxembourg'],
  mw: ['Malawi'],
  my: ['Malaysia'],
  mt: ['Malta'],
  mu: ['Mauritius'],
  mx: ['Mexico', 'United Mexican States'],
  ma: ['Morocco'],
  na: ['Namibia'],
  nl: ['Netherlands'],
  nz: ['New Zealand'],
  ng: ['Nigeria'],
  no: ['Norway'],
  om: ['Oman'],
  pk: ['Pakistan'],
  ps: ['Palestinian Authority'],
  pe: ['Peru'],
  ph: ['Philippines'],
  pl: ['Poland'],
  pt: ['Portugal'],
  qa: ['Qatar'],
  ro: ['Romania'],
  ru: ['Russia', 'Russian Federation'],
  sa: ['Saudi Arabia'],
  rs: ['Serbia'],
  sg: ['Singapore'],
  sk: ['Slovakia'],
  si: ['Slovenia'],
  za: ['South Africa'],
  kr: ['South Korea', 'Republic of Korea'],
  es: ['Spain', 'Kingdom of Spain'],
  lk: ['Sri Lanka'],
  se: ['Sweden', 'Kingdom of Sweden'],
  ch: ['Switzerland', 'Swiss Confederation'],
  tw: ['Taiwan'],
  tz: ['Tanzania'],
  th: ['Thailand'],
  tt: ['Trinidad & Tobago'],
  tn: ['Tunisia'],
  tr: ['Turkey'],
  ug: ['Uganda'],
  ua: ['Ukraine'],
  ae: ['United Arab Emirates'],
  gb: ['United Kingdom', 'UK', 'Great Britain'],
  us: ['United States', 'USA', 'United States of America'],
  ve: ['Venezuela'],
  vn: ['Vietnam'],
  zm: ['Zambia'],
  zw: ['Zimbabwe'],
} as const

export type CountryCode = keyof typeof COUNTRY_CODES_ISOALPHA2

/**
 * Country data for the country picker
 * Includes all countries and territories in the iso 3166-1 alpha-2 format
 */
export const countryPickerData = Object.entries({
  ...{ [COUNTRY_VALUE_ALL]: ['Global'] },
  ...COUNTRY_CODES_ISOALPHA2,
}).map(([value, label]) => ({ value: value, label })) as Array<{
  value: CountryCode | typeof COUNTRY_VALUE_ALL
  label: string[]
}>

export type CountryItem = (typeof countryPickerData)[number]
